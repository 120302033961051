<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Product Model</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm autocomplete="off" @submit.prevent="submit" v-else>
            <CCardBody>
              <CRow>
                <CCol sm="4">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Product Group</label
                      >
                    </div>
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="product_model_to_edit.product_group.id"
                      @change="
                        computedBrand(product_model_to_edit.product_group.id)
                      "
                      :addInputClasses="
                        product_group_id_invalid ? 'is-invalid' : null
                      "
                      :isValid="
                        product_model_to_edit.product_group.id ? true : null
                      "
                    >
                      <template v-for="product_group in product_groups">
                        <option
                          :key="product_group.id"
                          :value="product_group.id"
                        >
                          {{ product_group.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
                <CCol sm="4" v-if="product_model_to_edit.product_group.id">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Brand</label
                      >
                    </div>
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="product_model_to_edit.brand.id"
                      @change="
                        computedProductCategories(
                          product_model_to_edit.brand.id
                        )
                      "
                      :addInputClasses="brand_id_invalid ? 'is-invalid' : null"
                      :isValid="product_model_to_edit.brand.id ? true : null"
                    >
                      <template v-for="brand in brands">
                        <option :key="brand.id" :value="brand.id">
                          {{ brand.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
                <CCol sm="4" v-else>
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Brand</label
                      >
                    </div>
                    <select
                      required
                      disabled
                      class="custom-select"
                      id="inputGroupSelect01"
                      :addInputClasses="brand_id_invalid ? 'is-invalid' : null"
                      :isValid="product_model_to_edit.brand.id ? true : null"
                    ></select>
                  </div>
                </CCol>
                <CCol
                  sm="4"
                  v-if="
                    product_model_to_edit.brand.id &&
                    product_model_to_edit.product_group.id
                  "
                >
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Product Category</label
                      >
                    </div>
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="product_model_to_edit.product_category.id"
                      :addInputClasses="
                        product_category_id_invalid ? 'is-invalid' : null
                      "
                      :isValid="
                        product_model_to_edit.product_category.id ? true : null
                      "
                    >
                      <template v-for="product_category in product_categories">
                        <option
                          :key="product_category.id"
                          :value="product_category.id"
                        >
                          {{ product_category.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
                <CCol sm="4" v-else>
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Product Category</label
                      >
                    </div>
                    <select
                      disabled
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      :addInputClasses="
                        product_category_id_invalid ? 'is-invalid' : null
                      "
                      :isValid="
                        product_model_to_edit.product_category.id ? true : null
                      "
                    ></select>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol sm="12">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Product Model"
                    v-model="product_model_to_edit.name"
                    placeholder="Enter model name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="product_model_to_edit.name ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Address"
                    v-model="product_model_to_edit.description"
                    placeholder="Enter Description..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductModelEdit",
  data() {
    return {
      product_model_to_edit: [],
      name_invalid: false,
      product_group_id_invalid: false,
      brand_id_invalid: false,
      product_category_id_invalid: false,
    };
  },
  computed: {
    ...mapState({
      product_groups: (state) => state.product_groups.product_groups,
      brands: (state) => state.brands.computedBrands,
      product_categories: (state) =>
        state.product_categories.computedProductCategories ||
        state.product_categories.product_categories,
      editProductModel: (state) => state.product_models.editProductModel,
      isSaving: (state) => state.product_models.isSaving,
      isLoading: (state) => state.product_models.isLoading,
    }),
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editProductModel) {
      this.$router.push({ name: "Product Models" });
    }
    this.$store.commit("product_models/loading", true);
    this.$store.dispatch("product_groups/getAllProductGroups");
    this.$store.dispatch("brands/getAllBrands");
    this.$store.dispatch("product_categories/getAllProductCategories");
    this.beforeEditingCache = JSON.stringify(this.editProductModel);
    this.product_model_to_edit = this.editProductModel
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
  },

  methods: {
    computedBrand(product_group_id) {
      this.$store.dispatch("brands/computedBrands", product_group_id);
    },
    computedProductCategories(brand_id) {
      this.$store.dispatch(
        "product_categories/computedProductCategories",
        brand_id
      );
    },
    check_name() {
      if (!this.product_model_to_edit.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },
    check_product_group_id() {
      if (!this.product_model_to_edit.product_group.id) {
        this.product_group_id_invalid = true;
      } else {
        this.product_group_id_invalid = false;
      }
    },
    check_brand_id() {
      if (!this.product_model_to_edit.brand.id) {
        this.brand_id_invalid = true;
      } else {
        this.brand_id_invalid = false;
      }
    },
    check_product_category_id() {
      if (!this.product_model_to_edit.product_category.id) {
        this.product_category_id_invalid = true;
      } else {
        this.product_category_id_invalid = false;
      }
    },
    checkForm() {
      if (!this.product_model_to_edit.name) {
        this.check_name();
      } else {
        return true;
      }
      if (!this.product_model_to_edit.product_group.id) {
        this.check_product_group_id();
      } else {
        return true;
      }
      if (!this.product_model_to_edit.brand.id) {
        this.check_brand_id();
      } else {
        return true;
      }
      if (!this.product_model_to_edit.product_category.id) {
        this.check_product_category_id();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store.dispatch("product_models/updateProductModel", {
          id: this.product_model_to_edit.id,
          name: this.product_model_to_edit.name,
          description: this.product_model_to_edit.description,
          product_group_id: this.product_model_to_edit.product_group.id,
          brand_id: this.product_model_to_edit.brand.id,
          product_category_id: this.product_model_to_edit.product_category.id,
        });
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 